import React, {useEffect, useRef, useState} from 'react'
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {changeReservationTime, changeTransportationStatus, getTransportationInfo} from "../redux/actions";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from "@mui/material";
import logo from "../assets/logo.png";
import TransportationsTable from "../components/TransportationsTable";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import SelectSearch from 'react-select-search';
import '../styles/app.css'

var moment = require('moment-timezone');

const TransportationPage = () => {
    const {id} = useParams()
    const token = useSelector(state => state.token)
    const [information, setInformation] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const [showPlannedStartDate, setShowPlannedStartDate] = useState(false)
    const [showPlannedEndDate, setShowPlannedEndDate] = useState(false)
    const [showCancelTransportation, setShowCancelTransportation] = useState(false)

    useEffect(() => {
        if (id && token) {
            dispatch(getTransportationInfo({
                token,
                id,
                onSuccess: (res) => {
                    setInformation(res)
                }
            }))
        }
    }, [id, token])

    const timeStartChange = (e) => {
        setInformation({
            ...information,
            pick_up_time: {
                planned: e.target.value
            }
        })
    }

    const timeEndChange = (e) => {
        setInformation({
            ...information,
            return_time: {
                planned: e.target.value
            }
        })
    }

    const onReservationStart = () => {
        dispatch(
            changeTransportationStatus({
                token,
                transportationId: id,
                status: 'picked_up',
                onSuccess: (res) => {
                    dispatch(getTransportationInfo({
                        token,
                        id,
                        onSuccess: (res) => {
                            setInformation(res)
                        }
                    }))
                }
            })
        )
    }

    const onReservationStop = () => {
        dispatch(
            changeTransportationStatus({
                token,
                transportationId: id,
                status: 'returned',
                onSuccess: (res) => {
                    dispatch(getTransportationInfo({
                        token,
                        id,
                        onSuccess: (res) => {
                            setInformation(res)
                        }
                    }))
                }
            })
        )
    }

    const onReservationCancel = () => {
        dispatch(
            changeTransportationStatus({
                token,
                transportationId: id,
                status: 'cancelled',
                onSuccess: (res) => {
                    dispatch(getTransportationInfo({
                        token,
                        id,
                        onSuccess: (res) => {
                            setInformation(res)
                        }
                    }))
                    setShowCancelTransportation(false)
                }
            })
        )
    }

    const onUpdateHandler = () => {
        dispatch(
            changeReservationTime({
                token,
                id,
                startDate: information.pick_up_time.planned,
                endDate: information.return_time.planned,
                return_location_id: information.return_location.id,
                onSuccess: (res) => {
                    dispatch(getTransportationInfo({
                        token,
                        id,
                        onSuccess: (res) => {
                            setInformation(res)
                            setShowPlannedStartDate(false)
                            setShowPlannedEndDate(false)
                        }
                    }))
                },
                onError: (err) => {
                    alert(err.response.data.error.message)
                }
            })
        )
    }

    const [returnLocations, setReturnLocations] = useState([])

    useEffect(() => {
        if (information) {
            const newLocations = information.return_locations.map((item) => {
                return {
                    name: item.name,
                    value: item.id
                }
            })

            setReturnLocations(newLocations)
        }
    }, [information])

    const onSelectReturnLocationChange = (e) => {
        dispatch(
            changeReservationTime({
                token,
                id,
                startDate: information.pick_up_time.planned,
                endDate: information.return_time.planned,
                return_location_id: e,
                onSuccess: (res) => {
                    dispatch(getTransportationInfo({
                        token,
                        id,
                        onSuccess: (res) => {
                            setInformation(res)
                            setShowPlannedStartDate(false)
                            setShowPlannedEndDate(false)
                        }
                    }))
                },
                onError: (err) => {
                    alert(err.response.data.error.message)
                }
            })
        )
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#E9392C'
                }}>
                    <img onClick={ () => {history.push('/')} } src={logo}/>
                </Box>
            </Grid>
            {
                information ?
                    <Grid item xs={12} style={{padding: '8px'}}>
                        <h3 style={{textAlign: 'center'}}>Informatie</h3>
                        <p style={{borderBottom: '1px solid gray', paddingBottom: '8px', lineHeight: '1.5rem'}}><b>Naam
                            bestuurder</b>: {information ? information.driver.name : '-'}</p>
                        <p style={{borderBottom: '1px solid gray', paddingBottom: '8px', lineHeight: '1.5rem'}}>
                            <b>Voertuig</b>: {information ? information.vehicle.name + ` (${information.vehicle.license_plate})` : '-'}
                        </p>
                        <p style={{borderBottom: '1px solid gray', paddingBottom: '8px', lineHeight: '1.5rem'}}><b>Ophaal
                            locatie</b>: {information ? information.pick_up_location.name : '-'}</p>

                        <p style={{lineHeight: '1.5rem'}}>
                            <b>Retourlocatie</b></p>

                        <SelectSearch
                            name="return_location_id"
                            value={information.return_location.id}
                            onChange={onSelectReturnLocationChange}
                            style={{
                                width: '100%'
                            }}
                            search={true}
                            options={returnLocations} />

                        <p style={{borderBottom: '1px solid gray', paddingBottom: '8px', lineHeight: '1.5rem'}}><b>Tankpas
                            PIN</b>: {information ? information.vehicle.pin : '-'}</p>

                        <p style={{borderBottom: '1px solid gray', paddingBottom: '8px', lineHeight: '1.5rem'}}>
                            <b>Gereden kilomers</b>: {information ? information.driven_kilometers : '-'}</p>

                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <h3 style={{textAlign: 'center'}}>Planning</h3>
                            <Button onClick={onUpdateHandler} color="primary">Update tijd</Button>
                        </div>

                        <p style={{borderBottom: '1px solid gray', paddingBottom: '8px', lineHeight: '1.5rem'}}>
                            <b>Startdatum</b> <br/>

                            {
                                showPlannedStartDate ?
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <p style={{marginRight: '10px'}}>Planned:</p>
                                        <input
                                            onChange={timeStartChange}
                                            style={{fontFamily: 'Roboto', fontSize: '16px'}}
                                            type="datetime-local"
                                            value={moment(information.pick_up_time.planned).format('YYYY-MM-DDTHH:mm')}/>
                                    </div>
                                    :
                                    <div onClick={() => {setShowPlannedStartDate(true)}} style={{display: 'flex'}}>
                                        Planned: {information.pick_up_time.planned}
                                        <div style={{marginLeft: '5px'}}><EditIcon/></div>
                                    </div>
                            }


                            Actual : {information.pick_up_time.actual ? information.pick_up_time.actual : '-'} </p>

                        <p style={{borderBottom: '1px solid gray', paddingBottom: '8px', lineHeight: '1.5rem'}}>
                            <b>Einddatum</b> <br/>

                            {
                                showPlannedEndDate ?
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <p style={{marginRight: '10px'}}>Planned:</p>
                                        <input
                                            onChange={timeEndChange}
                                            style={{fontFamily: 'Roboto', fontSize: '16px'}}
                                            type="datetime-local"
                                            value={moment(information.return_time.planned).format('YYYY-MM-DDTHH:mm')}/>
                                    </div>
                                    :
                                    <div onClick={ () => {setShowPlannedEndDate(true)} } style={{display: 'flex'}}>
                                        Planned: {information.return_time.planned}
                                        <div style={{marginLeft: '5px'}}><EditIcon/></div>
                                    </div>
                            }


                            Actual: {information.return_time.actual ? information.return_time.actual : '-'}
                        </p>

                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            {
                                information.status === 'new' && information.cloudboxx.support == true ?
                                    <Button disabled={!information.is_pickable} onClick={onReservationStart}
                                            color="success" variant="contained">Deur openen</Button>
                                    :
                                    ''
                            }

                            {
                                information.status === 'picked_up' && information.cloudboxx.support == true ?
                                    <Button onClick={onReservationStop} color="success" variant="contained">Deur en
                                        reservering sluiten</Button>
                                    :
                                    ''
                            }

                            <Button
                                onClick={() => setShowCancelTransportation(true)}
                                disabled={!information.is_cancelable}
                                variant="contained"
                                color="error">Annuleer</Button>

                            <Button onClick={() => {
                                history.push('/')
                            }}> <ArrowBackIcon/> Terug</Button>
                        </div>

                        {
                            showCancelTransportation ?
                                <CancelTransportationModal handleNo={() => setShowCancelTransportation(false)} handleYes={onReservationCancel} />
                                :
                                ''
                        }
                    </Grid>
                    :
                    ''
            }

        </Grid>
    )
}

const CancelTransportationModal = ({handleNo, handleYes}) => {
    return (
        <Dialog
            maxWidth="sm"
            open={true}
            onClose={handleNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle style={{fontSize: '16px'}} id="alert-dialog-title">
                Wilt u uw reservering annuleren?
            </DialogTitle>
            <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button onClick={handleNo}>Nee</Button>
                <Button variant="contained" onClick={handleYes}>Ja</Button>
            </DialogActions>
        </Dialog>
    )
}

export default TransportationPage
